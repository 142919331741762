html {
  background-color: #f5f5f5;
}

* {
  font-family: "manrope", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  font-family: "owners", sans-serif;
  font-weight: 500;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

body {
  position: relative;
  overflow: hidden;
  color: #002635;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px #f5f5f5 inset;
}
